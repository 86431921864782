
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { getTestPort } from "@/utils/common";
import dayjs from "dayjs";
const user = namespace("user");

@Component({})
export default class DataImport extends Vue {
  @Prop({ default: "" })
  templateUrl: string;
  @Prop({ default: "" })
  actionUrl: string;
  @Prop({ default: false })
  visible: boolean;
  @user.State token;
  dayjs = dayjs;
  dialogVisible = false;
  upPendingFlag = false;
  dangerFailure = false;
  disableFlag = true;
  successBody = false;
  successBodyDis = false;
  successNum = 0;
  failNum = 0;
  diaTitle = "批量导入";
  compeletTime = "";
  allData = 0;
  headers = {
    userAuthToken: ""
  };
  resultFileUrl = ""; //下载导入失败文件路径
  fileList = [];
  get $visible() {
    return this.visible;
  }
  get $uploadUrl() {
    return getTestPort() + this.actionUrl;
  }
  created() {
    this.headers.userAuthToken = this.token;
  }
  submitUpload() {
    const ref: any = this.$refs.upload;
    ref.submit();
  }
  handleRemove(file: any, fileList: any) {
    console.log(file, fileList);
    this.disableFlag = true;
  }
  handlePreview(file: any) {
    console.log(file, "preview");
  }
  errorEvent() {
    console.log("上传失败");
    this.dangerFailure = true;
    this.upPendingFlag = false;
    this.disableFlag = true;
    this.fileList = [];
  }
  upPending() {
    console.log("上传中");
    this.dangerFailure = false;
    this.upPendingFlag = true;
  }
  upSuccess(response, file, fileList) {
    this.upPendingFlag = false;
    console.log(response, file, fileList, "response, file, fileList,上传成功");
    if (response.data) {
      this.diaTitle = "批量导入结果";
      this.successBody = true;
      this.disableFlag = false;
      const { failedLines, resultFilePath, totalLines } = response.data;
      this.successNum = totalLines - failedLines;
      this.allData = totalLines;
      this.failNum = failedLines;
      this.compeletTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.resultFileUrl = resultFilePath;
    } else {
      this.dangerFailure = true;
      this.fileList = [];
      this.disableFlag = true;
    }
  }
  changeUpload(file: any, fileList: any) {
    console.log("上传文件", file, fileList);
    if (fileList.length > 0 && file.status == "ready") {
      this.disableFlag = false;
    }
  }
  handleClose() {
    this.$emit("update:visible", false);
  }
  cancelOrContinue() {
    if (this.diaTitle == "批量导入") {
      this.fileList = [];
      this.disableFlag = true;
      this.dialogVisible = false;
      this.upPendingFlag = false;
      this.dangerFailure = false;
      this.successBody = false;
      this.handleClose();
    } else {
      console.log("继续导入");
      this.fileList = [];
      this.upPendingFlag = false;
      this.dangerFailure = false;
      this.disableFlag = true;
      this.successBody = false;
      this.diaTitle = "批量导入";
    }
  }
  handleDownloadFailData() {
    if (this.resultFileUrl) {
      this.successBodyDis = false;
      window.open(this.resultFileUrl);
    } else {
      this.successBodyDis = true;
    }
  }
}
